<template>
  <div>
    <div class="row">
      <div class="col-xl-12 col-lg-12">
        <KTPortlet v-bind:title="'Políticas de Privacidad'">
          <template v-slot:body>
Última actualización: 21 julio de 2022
<br/><br/> 
StarkOMS está comprometido con la privacidad y protección de los datos de sus usuarios.
<br/><br/> 
Esta Política de privacidad describe la metodología y consideraciones que toma StarkOMS para recopilar, usar, proteger y compartir la información de nuestros clientes y usuarios, incluyendo la información que nos comparten, información que obtenemos cuando acceden y usan nuestro software, incluso cuando envían preguntas o comentarios.
<br/><br/> 
Esta Política de privacidad aplica a (i) cualquier persona con un cuenta registrada en nuestros servicios (cliente), (ii) todos aquellos que usan el servicio a nombre del cliente (usuario final) y (iii) la información proporcionada por nuestros clientes, misma que es utilizada para la operación de StarkOMS.
<br/><br/> 
Recomendamos leer atentamente esta Política de privacidad para comprender cómo trataremos su información antes de que comience a utilizar el servicio de StarkOMS o se comunique con nosotros a través de los canales de comunicación habilitados o un ejecutivo comercial. Esta Política de privacidad también pretende explicar las condiciones bajo las cuales StarkOMS usa y divulga esa información, y sus derechos en relación con esa información. 
<br/><br/> 
Para efectos de esta Política de Privacidad, se definen los siguientes términos:
<br/><br/> 
<p><b>"Usuario"</b>: significa cualquier persona o entidad que acceda o utilice el sitio web de cualquier manera. Las referencias al "acceso" y/o "uso" del sitio web (y cualquier variación del mismo como software o servicio) incluyen los actos de acceder o navegar por el sitio web y acceder o usar los servicios.</p>
<br/>
<p><b>"Sitio web"</b>: se refiere a todos y cada uno de los sitios web propiedad de StarkOMS u operados por este (incluido el sitio web que actualmente se encuentra en https://app.starkoms.com/), a través de los cuales está disponible el acceso a los servicios de StarkOMS. Las referencias al "Sitio web" se extienden a todo el software, a todas y cada una de sus funcionalidades y contenido relacionados.</p>
<br/>
 
<b><i>Al acceder o utilizar nuestro servicio usted acepta y consiente las prácticas descritas en esta Política de privacidad, la misma que puede ser actualizada y modificada las veces que se requiera. </i></b>
<br/>
A continuación se detalla el contenido de nuestra Política de privacidad:
<br/>
&nbsp;&nbsp;&nbsp; 1.	Cómo capturamos los datos y/o información.<br/>
&nbsp;&nbsp;&nbsp; 2.	Cómo los datos pueden ser USADOS.<br/>
&nbsp;&nbsp;&nbsp; 3.	Cómo los datos pueden ser COMPARTIDOS.<br/>
&nbsp;&nbsp;&nbsp; 4.	Cookies y Beacons.<br/>
&nbsp;&nbsp;&nbsp; 5.	Decisiones de nuestros usuarios.<br/>
&nbsp;&nbsp;&nbsp; 6.	Seguridad de la información.<br/>
&nbsp;&nbsp;&nbsp; 7.	Menores de edad.<br/>
&nbsp;&nbsp;&nbsp; 8.	Enlaces a sitios web de terceros (3PL).<br/>
&nbsp;&nbsp;&nbsp; 9.	Jurisdicción internacional.<br/>
&nbsp;&nbsp;&nbsp; 10.	Cambios a la Política de Privacidad.<br/>
&nbsp;&nbsp;&nbsp; 11.	Contactos.<br/>

<br/><br/><br/> 
<b>1. 	Cómo capturamos los datos y/o información.</b>
<br/>
<b>Información que nos proporciona</b><br/> 
Recopilamos información que el usuario nos proporciona directamente, incluso cuando se registra para obtener una cuenta, utiliza el servicio de StarkOMS, actualiza sus preferencias de correo electrónico, responde a una encuesta, deja comentarios sobre el sitio web, asiste a uno de nuestros eventos, expresa un interés en StarkOMS, toma contacto por correo electrónico u otro medio de comunicación habilitado.
<br/><br/> 
También podemos recopilar información si una empresa u organización autoriza a usuarios a administrar su cuenta de StarkOMS bajo su representación.     
<br/><br/> 
No solicitaremos información confidencial, como identificadores gubernamentales, información médica o financiera (excepto en relación con los pagos). No nos proporcione esta información a través de correos electrónicos, formularios o de ninguna otra manera, a menos que se solicite específicamente por un ejecutivo acreditado de StarkOMS.
<br/><br/> 
 
<b>Información generada a partir del uso del StarkOMS</b><br/> 
Adicionalmente recopilamos información técnica cuando accede, navega y utiliza nuestro sitio web, incluida la información que recibimos y registramos automáticamente desde su navegador o plataforma móvil en nuestro servidor de logs. Esta información técnica nos ayuda a operar y maximizar las capacidades del software, tales como:
<br/>
  <dd>&nbsp;&nbsp;&bull;&nbsp;Información sobre los dispositivos que utiliza para acceder a nuestro sitio web, tales como: dirección MAC, dirección IP, tipo y versión del navegador, ubicación, configuración de la zona horaria, tipos y versiones de los complementos del navegador, sistema operativo y plataforma, tipo de dispositivo, el dispositivo e identificadores de aplicaciones, información operativa, operador de servicio móvil y cookies;</dd>
  <dd>&nbsp;&nbsp;&bull;&nbsp;Información sobre sus visitas al sitio web, el flujo de clics completo de la URL, hacia, dentro y desde el sitio web, incluyendo las fechas y horas;</dd>
  <dd>&nbsp;&nbsp;&bull;&nbsp;Información que necesitamos y usamos para facilitar el uso de nuestro sitio web (incluso para brindar acceso a sitios web o servicios de terceros), como solicitudes de URL, direcciones IP de destino o detalles de configuración del dispositivo;</dd>
  <dd>&nbsp;&nbsp;&bull;&nbsp;Dentro de StarkOMS, las páginas que ve, las búsquedas que realiza, la cantidad de tiempo que navega por los resultados de búsqueda, los resultados de búsqueda específicos que selecciona para ver, la duración de las visitas a páginas, la información de interacción por página (como desplazamiento, clics y movimientos del mouse), su compromiso con ciertos elementos variables/dinámicos de una página y métodos utilizados para navegar fuera de la página; y</dd>
  <dd>&nbsp;&nbsp;&bull;&nbsp;Tiempos de respuesta de la página y errores de descarga.</dd>
  <br/>
StarkOMS utiliza cookies y beacons para capturar parte de esta información. Mayores detalles están disponibles en la sección “Cookies y Beacons”.<br/>            
StarkOMS también puede recibir – por autorización y consentimiento inicial del usuario- la información de transacciones, órdenes o pedidos, provenientes de otros sistemas externos. Esta información incluye pero no se limita a: nombre del comprador, dirección, teléfono, detalle de ítems comprados, montos totales, entre otros.
<br/><br/> <br/> 
 

<b>2. Cómo los datos pueden ser USADOS.</b>
<br/>
<b>Información de identificación personal.</b>
<br/>
Parte de la información que recopilamos a través de su uso StarkOMS puede identificar personalmente a un individuo ("Información de identificación personal"). Los tipos de información de identificación personal que se pueden utilizar dentro del uso del servicio incluyen:
<br/>
  <dd>&nbsp;&nbsp;&bull;&nbsp;Información de contacto (como nombre, dirección, email y número de teléfono);</dd>
  <dd>&nbsp;&nbsp;&bull;&nbsp;Contraseñas de cuenta para el sitio web;</dd>
  <dd>&nbsp;&nbsp;&bull;&nbsp;Ubicación geográfica.</dd>
<br/>
Otros datos derivados de su uso de StarkOMS se tratan como "información de identificación no personal", a menos que se combinen con información de identificación personal, o a menos que la ley aplicable exija lo contrario.
<br/>
Utilizaremos la información de identificación personal recopilada sólo y exclusivamente para:
<br/>
  <dd>&nbsp;&nbsp;&bull;&nbsp;Crear y administrar la cuenta;</dd>
  <dd>&nbsp;&nbsp;&bull;&nbsp;Proporcionar al Usuario acceso al servicio;</dd>
  <dd>&nbsp;&nbsp;&bull;&nbsp;Operar nuestro sitio, incluyendo la gestión de acceso, el procesamiento de órdenes, la administración del sitio web, las operaciones internas, la resolución de problemas, el análisis de datos, las pruebas, la investigación, las estadísticas y las encuestas;</dd>
  <dd>&nbsp;&nbsp;&bull;&nbsp;Enviar al Usuario oportunamente información que le permita utilizar nuestro servicio;</dd>
  <dd>&nbsp;&nbsp;&bull;&nbsp;Informar al Usuario sobre la actividad en su cuenta;</dd>
  <dd>&nbsp;&nbsp;&bull;&nbsp;Generar informes sobre el uso de nuestro servicio por parte de los Usuarios;</dd>
  <dd>&nbsp;&nbsp;&bull;&nbsp;Responder a sus solicitudes, comentarios o consultas;</dd>
  <dd>&nbsp;&nbsp;&bull;&nbsp;Notificar al Usuario sobre actualizaciones, información o alertas con respecto al sitio web;</dd>
  <dd>&nbsp;&nbsp;&bull;&nbsp;Cumplir con las leyes, reglamentos y otros requisitos legales;</dd>
  <dd>&nbsp;&nbsp;&bull;&nbsp;Cumplir con los estándares relevantes de la industria y nuestras políticas;</dd>
  <dd>&nbsp;&nbsp;&bull;&nbsp;Proteger y hacer cumplir nuestros derechos y los derechos de los Usuarios contra actividades ilegales, incluido el robo de identidad y el fraude.</dd>
  <dd>&nbsp;&nbsp;&bull;&nbsp;Proteger y hacer cumplir nuestros derechos derivados de cualquier acuerdo celebrado entre el Usuario y StarkOMS, incluida la facturación y el cobro;</dd>
  <dd>&nbsp;&nbsp;&bull;&nbsp;Proteger la integridad y mantener la seguridad de nuestro sitio web, incluidas las áreas seguras del sitio web;</dd>
  <dd>&nbsp;&nbsp;&bull;&nbsp;Operar, evaluar y mejorar nuestro servicio, lo que incluye, entre otros, realizar encuestas e investigaciones de mercado, desarrollar nuevos productos, servicios y promociones (como, por ejemplo, eventos especiales, programas, otros), analizar y mejorar los productos existentes, servicios y promociones, gestión de nuestras comunicaciones; realizar tareas de contabilidad, auditoría y otras funciones internas;</dd>

<br/><br/> 
<b>Información de identificación no personal.</b>
<br/>
Adicional al apartado anterior, también podemos usar “información de identificación no personal” para presentar contenido de una manera optimizada para su dispositivo.
<br/>
Podemos combinar información técnica, o información de identificación no personal, sobre su uso de nuestro sitio web con información que obtenemos de otros Usuarios para usar de manera agregada o anónima para fines similares.
<br/><br/> <br/> 

<b>3. 	Cómo los datos pueden ser COMPARTIDOS.</b>
<br/>
<b>Información de identificación personal.</b>
<br/>
StarkOMS no comercializará ni compartirá su información de identificación personal con terceros para fines de marketing directo sin su consentimiento expreso.
<br/><br/> 
Podemos compartir información de identificación personal con:
<br/><br/> 
  <dd>&nbsp;&nbsp;&bull;&nbsp;Las empresas o entidades externas que contratan los servicios tecnológicos de StarkOMS.</dd>
  <dd>&nbsp;&nbsp;&bull;&nbsp;Otras organizaciones asociadas con esas empresas para permitir que sus sistemas operen con el sitio web de StarkOMS;</dd>
  <dd>&nbsp;&nbsp;&bull;&nbsp;Nuestras subsidiarias y afiliadas;</dd>
  <dd>&nbsp;&nbsp;&bull;&nbsp;Nuestros proveedores de servicios en la medida razonablemente necesaria para permitirnos operar nuestro servicio y proporcionar el acceso a nuestro sitio web;</dd>
  <dd>&nbsp;&nbsp;&bull;&nbsp;Un comprador u otro sucesor en el caso de una fusión, desinversión, reestructuración, reorganización, disolución, liquidación u otra venta o transferencia de algunos o todos nuestros activos, ya sea como empresa en funcionamiento o como parte de una quiebra, liquidación o procedimiento similar , en el que la información de identificación personal que tenemos sobre nuestros usuarios se encuentra entre los activos transferidos;</dd>
  <dd>&nbsp;&nbsp;&bull;&nbsp;Otros terceros, únicamente con su consentimiento expreso.</dd>
<br/><br/> 
No permitimos que estos terceros utilicen la Información de identificación personal que compartimos para ningún otro propósito que no sea (i) como se describe en esta Política de privacidad y (ii) para cumplir con sus propias exigencias legales.
<br/><br/> 
También podemos divulgar información de identificación personal con autoridades, funcionarios gubernamentales u otros terceros según sea necesario con el fin de:
<br/> 
  <dd>&nbsp;&nbsp;&bull;&nbsp;Cumplir con cualquier orden judicial, ley o proceso legal, incluso para responder a cualquier solicitud gubernamental o regulatoria;</dd>
  <dd>&nbsp;&nbsp;&bull;&nbsp;Prevención del fraude y reducción del riesgo crediticio;</dd>
  <dd>&nbsp;&nbsp;&bull;&nbsp;Investigar el posible acceso no autorizado o mal uso de nuestro sitio web u otro incumplimiento de nuestros Términos de Uso,</dd>
  <dd>&nbsp;&nbsp;&bull;&nbsp;Proteger los activos o la propiedad y hacer valer los derechos de StarkOMS, incluso con fines de facturación y cobro; y</dd>
  <dd>&nbsp;&nbsp;&bull;&nbsp;Proteger los derechos, la propiedad o la seguridad de nuestros Usuarios u otros.</dd>
<br/>
En el caso de que recibamos una solicitud de una entidad gubernamental para proporcionarle su Información de identificación personal, haremos los intentos razonables para notificarle dicha solicitud, en la medida de lo razonablemente posible y legalmente permitido.
<br/><br/> 

<b>Información de identificación no personal.</b>
<br/>
Además, podemos compartir información de identificación no personal, incluidos datos agregados o anónimos con:
<br/><br/> 
<dd>&nbsp;&nbsp;&bull;&nbsp;Servicios de análisis de datos, motores de búsqueda u otros proveedores de servicios que nos ayuden a mejorar nuestro sitio web;</dd>
<dd>&nbsp;&nbsp;&bull;&nbsp;Con otros Usuarios o posibles Usuarios de StarkOMS.</dd>

<br/><br/> 
 <b>4. 	Cookies y Beacons.</b>
 <br/> 
Podemos usar cookies, beacons y tecnologías similares, ahora o en el futuro, para respaldar la funcionalidad de nuestro servicio. Esto proporciona una mejor experiencia cuando visita nuestro sitio web y nos permite mejorar nuestro servicio. Nuestros proveedores de servicios pueden usar cookies y beacons para recopilar y compartir información de identificación no personal sobre sus actividades tanto en nuestro sitio web como en otros sitios web. Además, los terceros que no están afiliados a nosotros también pueden recopilar información sobre usted, incluido el seguimiento de su historial de navegación, cuando utiliza nuestro sitio web. No tenemos control sobre estas prácticas de cobro de recolección de datos. Si desea minimizar esta captura de datos de terceros, puede ajustar la configuración de su navegador o instalar complementos.
<br/><br/> 
Cookies: Una cookie del navegador es un pequeño archivo que se coloca en el disco duro de su computadora. Esa cookie luego se comunica con los servidores, los nuestros o los de otras compañías que autorizamos a recopilar datos para nosotros, y permite el reconocimiento de su computadora personal. Asociamos cookies con información de identificación personal sólo si usa las capacidades de reconocimiento automático en áreas restringidas del sitio web, solicita un servicio, usa los servicios de personalización disponibles como parte del sitio web o nos solicita que lo contactemos con información de marketing adicional. De lo contrario, no recopilamos información de identificación personal de las cookies del navegador y no asociamos las cookies del navegador con su información de identificación personal.
<br/><br/> 
Usted puede usar las herramientas disponibles en su computadora u otro dispositivo para configurar su navegador para que rechace o deshabilite todas o algunas de las cookies del navegador, o para que le avise cuando se están configurando cookies. Sin embargo, si rechaza o deshabilita todas las cookies del navegador, es posible que no pueda acceder a ciertas partes o usar ciertas características o funciones de StarkOMS.
<br/><br/> 
Usted puede elegir activar el reconocimiento automático cuando se registre a una cuenta. Después del registro, puede deshabilitar la cookie persistente que admite el reconocimiento utilizando las herramientas de su navegador. Si elige deshabilitar las cookies que admiten el reconocimiento automático, deberá volver a ingresar su ID de usuario (nombre o email) y contraseña cada vez que acceda a una parte privada del sitio web.
<br/><br/> 
A menos que haya ajustado la configuración de su navegador para que rechace todas las cookies, podemos usar cookies cuando dirige su navegador a nuestro sitio web.
<br/><br/> 
Para obtener más información sobre las cookies, puede visitar <a href="http://www.allaboutcookies.org." target="_blank">http://www.allaboutcookies.org.</a> 
<br/><br/> 
Cookies Flash: Ciertas características de nuestro sitio web pueden usar objetos almacenados locales llamados cookies flash para recopilar y almacenar información sobre sus preferencias y navegación hacia, desde y dentro de nuestro sitio web. También incluimos cookies en nuestros reproductores de vídeo alojados por terceros para contar la cantidad de espectadores únicos que ven el comunicado de prensa en video de un cliente y para proporcionar informes agregados. Las cookies no lo identifican como individuo ni rastrean su comportamiento en línea. No recopilamos información de identificación personal de las cookies flash y no las asociaremos con su información de identificación personal.
<br/><br/> 
Las cookies flash no se gestionan con la misma configuración del navegador que se utilizan para las cookies del navegador. Para saber cómo puede administrar su configuración de cookies Flash, visite la página de configuración del reproductor Flash en el sitio web de Adobe. Si deshabilita o rechaza las cookies de Flash, tenga en cuenta que algunas partes de nuestro sitio web pueden ser inaccesibles o no funcionar correctamente.
<br/><br/> 
Beacons: Nuestro sitio web y los correos electrónicos pueden contener pequeños archivos electrónicos conocidos como beacons (también llamados web beacons, GIF transparentes, píxel tags y single-pixel GIF) que nos permiten, por ejemplo, contar los usuarios que han visitado esas páginas o abierto un correo electrónico y para otras estadísticas relacionadas con el sitio web. Los beacons en las campañas de marketing por correo electrónico nos permiten rastrear sus respuestas y sus intereses en nuestro contenido, ofertas y páginas web. También puede utilizar las herramientas de su dispositivo para desactivar estas tecnologías.
<br/><br/> 
Google Inc. ("Google") es un ejemplo de un proveedor de servicios que utiliza cookies o tecnologías similares para recopilar información sobre sus actividades de navegación a lo largo del tiempo y en diferentes sitios web después de su visita a nuestro sitio web. Google Analytics es un servicio de análisis web proporcionado por Google que utiliza cookies propias para rastrear las interacciones de los usuarios y recopilar otra información sobre cómo usa nuestro sitio web. Google Analytics recopila información de forma anónima. Luego usamos la información para compilar informes, incluidas las tendencias de uso, para ayudarnos a mejorar nuestro sitio web. Puede optar por no participar en Google Analytics sin afectar la forma en que visita nuestro sitio. Para obtener más información sobre cómo usa Google estos datos, vaya a www.google.com/policies/privacy/partners/, o revise los Términos de servicio de Google Analytics en https://www.google.com/analytics/terms/us.html Para obtener más información sobre cómo optar por no ser rastreado por Google Analytics en todos los sitios web que utiliza, visite <a href="https://tools.google.com/dlpage/gaoptout/" target="_blank">https://tools.google.com/dlpage/gaoptout/</a>.
<br/><br/> 

<b>5.   Decisiones de los usuarios.</b>
<br/>
Le ofrecemos ciertas opciones en relación con la información que recopilamos de usted.

<br/><br/> 
<b>Comunicaciones por email.</b>
<br/>
Es posible que tenga la oportunidad de recibir ciertas comunicaciones relacionadas con nuestro servicio. Si nos proporcionó su dirección de correo electrónico para recibir comunicaciones, puede optar por no recibir más correos electrónicos de marketing en cualquier momento siguiendo las instrucciones en la parte inferior de nuestros correos electrónicos y ajustando sus preferencias de correo electrónico. Tenga en cuenta que ciertos correos electrónicos pueden ser necesarios para el funcionamiento de nuestro sitio web. Continuará recibiendo estos correos electrónicos, si corresponde, incluso si se da de baja de nuestras comunicaciones opcionales.
<br/><br/> 

<b>Cookies / Beacons.</b>
<br/>
Si desea minimizar la información recopilada por una cookie o un beacon, puede ajustar la configuración de su navegador para que le notifique cuando reciba una cookie, lo que le permite elegir si la acepta o no. También puede configurar su navegador para que rechace automáticamente cualquier cookie. También puede instalar complementos que cumplen funciones similares. Sin embargo, tenga en cuenta que es posible que algunas funciones y servicios de nuestro sitio web no funcionen correctamente. 
<br/><br/> 
 
<b>No rastrear.</b>
<br/>
Algunos navegadores admiten la función de "No rastrear" (o DNT, “Do Not Track” por sus siglas en inglés), una preferencia de privacidad que los usuarios pueden configurar en ciertos navegadores web, que pretende ser una señal para los sitios web y servicios de que no desea que lo rastreen en diferentes sitios web o servicios en línea que visite.
<br/><br/> 
StarkOMS actualmente no reconoce ni responde a las señales DNT, por lo que la configuración de DNT no cambia la forma en que opera el servicio.
<br/><br/> 
Tenga en cuenta que no podemos controlar cómo los sitios web de terceros o los servicios en línea que visita a través de nuestro sitio web responden a las señales de DNT. Consulte las políticas de privacidad de esos terceros para obtener información sobre sus prácticas de privacidad.
<br/><br/> 

<b>Actualización o eliminación de información.</b>
<br/><br/> 
La exactitud de la información que tenemos sobre cada uno de nuestros usuarios es muy importante. Los usuarios pueden actualizar su información en cualquier momento poniéndose en contacto con nosotros en <a href="mailto:support@starkoms.com">support@starkoms.com</a>.
<br/><br/> 
 

<b>6.   Seguridad de la información.</b>
<br/>
La seguridad de su información es importante para StarkOMS, y hemos establecido medidas de seguridad administrativas y técnicas diseñadas para proteger su información de identificación personal contra la alteración, el acceso, la pérdida, el robo, el uso o la divulgación no autorizados. Desafortunadamente, ningún sistema puede garantizar la seguridad completa de su información. Como resultado, StarkOMS no puede asegurar ni garantizar que su información, incluida su información de identificación personal, esté segura frente a terceros no autorizados.
<br/><br/> 
Por lo tanto, el uso de StarkOMS y la comunicación con terceros no autorizados corre bajo el propio riesgo del usuario.


<br/><br/> 
 <b>7. Menores de edad.</b>
<br/>
StarkOMS está dirigido y destinado para ser utilizado únicamente por personas mayores de 18 años. No recopilamos intencionadamente información de menores de 18 años. Si usted es menor de 18 años, no está autorizado, al registrar una cuenta o de otro modo, para enviarnos información de identificación personal, incluido su nombre, dirección o email. Si descubrimos que hemos recopilado información de identificación personal de un menor de 18 años, eliminaremos esa información de nuestra base de datos lo antes posible. Al enviarnos cualquier información de identificación personal, usted declara y garantiza que tiene 18 años de edad o más.
<br/><br/> 

<b>8. Enlaces a sitios web de terceros.</b>
<br/>
Nuestro sitio web puede contener enlaces a sitios web y servicios de terceros, incluidos los de nuestras alianzas o partners tecnológicos. Tenga en cuenta que estos enlaces se proporcionan para su comodidad e información, y que los sitios web y los servicios pueden funcionar de forma independiente a nosotros y tener sus propias políticas o avisos de privacidad, que le sugerimos que revise. Esta Política de privacidad se aplica únicamente a StarkOMS. No aceptamos ninguna responsabilidad u obligación por las políticas o prácticas de terceros. Si elige acceder a cualquier sitio web o servicio vinculado desde nuestro sitio web, verifique las políticas aplicables antes de usar o enviar datos personales a dicho sitio web o servicio.
<br/><br/> 

<b>9. Jurisdicciones internacionales.</b>
<br/>
El sitio web está alojado en servidores ubicados en los Estados Unidos de América y está sujeto a las leyes estatales y federales de dicha jurisdicción. Si accede a nuestro sitio web desde otras jurisdicciones, tenga en cuenta que nos está transfiriendo su información personal en los Estados Unidos y, al usar nuestro sitio web, acepta esta transferencia y uso de su información personal de acuerdo con estas políticas de privacidad. También acepta cumplir con las leyes estatales y federales de los Estados Unidos que resultaren aplicables respecto al uso del sitio web y sus acuerdos con nosotros. Cualquier persona que acceda a StarkOMS desde cualquier jurisdicción con leyes o reglamentos que rijan el uso de Internet, incluida la recopilación, el uso y la divulgación de datos personales, diferente de las jurisdicciones mencionadas anteriormente, solo puede usar el sitio web de una manera legal en su jurisdicción. Si el uso del sitio web fuera ilegal en su jurisdicción, no puede usar el sitio web.
<br/><br/> 

<b>10. Cambios en nuestra política de privacidad.</b>
<br/>
StarkOMS puede, a su pleno criterio, cambiar esta política de privacidad cuando lo estime conveniente. Todos y cada uno de los cambios a esta política de privacidad se reflejarán en esta página y la fecha de vigencia se indicará en la parte superior de esta política de privacidad. A menos que se indique lo contrario, nuestra política de privacidad actual se aplica a toda la información que tenemos sobre usted. Los Usuarios deben consultar periódicamente esta página para ver si se han producido cambios en esta política de privacidad. StarkOMS siempre publicará nuevas versiones de la política de privacidad en el sitio web. Sin embargo, StarkOMS puede, según lo determine a su discreción, decidir notificar a los Usuarios los cambios importantes realizados en esta política de privacidad por email u otro medio. En consecuencia, es importante que siempre mantenga y actualice su información de contacto.
<br/><br/> 
El uso continuo de StarkOMS o la comunicación con nosotros después de que se haya actualizado la política de privacidad (o cualquier otra indicación de su consentimiento) constituirá su aceptación de la política de privacidad actualizada.
<br/><br/> 
Tenga en cuenta que podemos condicionar el acceso a nuestro sitio web gracias a su consentimiento de los cambios en esta política de privacidad.
<br/><br/> 
 
<b>11. Contactos.</b>
<br/>
Si tiene preguntas o comentarios relacionados con esta Política de privacidad, o si desea que actualicemos la información que tenemos sobre usted, comuníquese con nosotros a support@starkoms.com.
          </template>
        </KTPortlet>
      </div>
    </div>
  </div>
</template>

<script>
  import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
  import KTPortlet from "@/views/partials/content/Portlet.vue";

  export default {
    name: "PrivacyPolicies",
    components: {
      KTPortlet,
    },
    mounted() {
      this.$store.dispatch(SET_BREADCRUMB, [{ title: "Políticas de Privacidad" }]);
    },
  };
</script>
